import {
	initialFilterState,
	type TReportFilter,
	type TReportFilterAction,
} from '@widgets/marketing-general-report/model';

export const reportFilterReducer = (
	state: TReportFilter | null,
	action: TReportFilterAction,
): TReportFilter | null => {
	switch (action.type) {
		case 'SET_VALUES': {
			return {
				...(state ?? initialFilterState),
				...action.payload,
			};
		}
		default: {
			return state ?? initialFilterState;
		}
	}
};
