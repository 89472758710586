import { format } from 'date-fns';

import { type TDateRangeValue } from '@shared/components/date-range-calendar';

const dateFormat = 'yyyy-MM-dd';

export const getExportFilename = (dateRange: TDateRangeValue, type: 'general' | 'detailed') =>
	dateRange.reduce<string>(
		(acc, value) => `${acc}_${format(new Date(String(value)), dateFormat)}`,
		`export_${type}_report`,
	);
