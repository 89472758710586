import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { BASE_URL } from '@shared/api/constants';
import { axiosInstance } from '@shared/api/axios-instance';

import { marketingReport } from './api';

export { type TGeneralMarketingResponse } from './types';
export { generalReportResponse } from './schemas';

export const marketingReportApiClient = new Zodios(BASE_URL, marketingReport, {
	axiosInstance,
});

export const marketingReportApiHooks = new ZodiosHooks('statistics', marketingReportApiClient);
