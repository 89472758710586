import React, { useEffect, useState } from 'react';
import { formatISO, subWeeks } from 'date-fns';
import Snackbar, { type SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

import {
	DateRangeFilterContainer,
	ReportFiltersContainer,
} from '@shared/components/report-filters-container';
import {
	CustomDateRangePicker,
	type TDateRangeValue,
} from '@shared/components/date-range-calendar';
import { AidList, useAidList } from '@entities/aid-list';
import { Button } from '@shared/components/button';
import { marketingReportApiHooks } from '@shared/api/marketing-report';
import { mapDateToBackend } from '@shared/mappers/map-date-to-backend';

const defaultDateRange: TDateRangeValue = [
	formatISO(subWeeks(new Date(), 1)),
	formatISO(new Date()),
];

const offsetStep = 1_000;
const pendingOffsetLimit = 1_000_000;

export const MarketingReportDownload: React.FC = () => {
	const [offset, setOffset] = useState(0);
	const [isError, setError] = useState<boolean>(false);
	const { aidList, isAidListSuccess } = useAidList();
	const [dateRange, setDateRange] = useState<TDateRangeValue>(defaultDateRange);
	const [selectedAidList, setSelectedAidList] = useState<number[]>();
	const [isUtmCsvPending, setIsUtmCsvPending] = useState<boolean>(false);

	const { refetch } = marketingReportApiHooks.useUtmCsv(
		{
			queries: {
				offset,
				aids: selectedAidList,
				dateFrom: mapDateToBackend(dateRange[0]),
				dateTo: mapDateToBackend(dateRange[1]),
			},
		},
		{
			retry: 0,
			onSuccess: (response) => {
				if (response.uploadUrl) {
					setOffset(0);
					setIsUtmCsvPending(false);
					window.open(response.uploadUrl, '_blank');
					return;
				}

				if (offset >= pendingOffsetLimit) {
					setError(true);
					setIsUtmCsvPending(false);
					return;
				}

				setOffset((currentOffset) => currentOffset + offsetStep);

				refetch().catch(() => {
					setError(true);
				});
			},
			onError: () => {
				setIsUtmCsvPending(false);
				setError(true);
			},
			enabled: false,
		},
	);

	useEffect(() => {
		if (isAidListSuccess && aidList) {
			setSelectedAidList(aidList.map(({ id }) => id));
		}
	}, [aidList, isAidListSuccess]);

	const isButtonDisabled =
		dateRange.includes(null) ||
		typeof selectedAidList === 'undefined' ||
		selectedAidList.length === 0 ||
		isUtmCsvPending;

	const handleDownloadClick = () => {
		setIsUtmCsvPending(true);
		refetch().catch(() => {
			setError(true);
		});
	};

	const handleCloseErrorToast = (
		_?: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason,
	) => {
		if (reason !== 'clickaway') {
			setError(false);
		}
	};

	return (
		<ReportFiltersContainer>
			<DateRangeFilterContainer>
				<CustomDateRangePicker value={dateRange} setValue={setDateRange} />
			</DateRangeFilterContainer>
			<AidList onSelect={setSelectedAidList} />
			<Button
				variant="contained"
				disabled={isButtonDisabled}
				onClick={handleDownloadClick}
				startIcon={isUtmCsvPending ? <SyncRoundedIcon /> : undefined}
			>
				Get report
			</Button>

			<Snackbar
				open={isError}
				autoHideDuration={2000}
				onClose={handleCloseErrorToast}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleCloseErrorToast}
					severity="error"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Something went wrong. Please try again later.
				</Alert>
			</Snackbar>
		</ReportFiltersContainer>
	);
};
