import { useState } from 'react';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import Snackbar, { type SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { Button } from '@shared/components/button';
import { mapDateToBackend } from '@shared/mappers/map-date-to-backend';
import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { marketingReportApiHooks } from '@shared/api/marketing-report';

import { handleDownloadCsv } from '../../lib/handle-download-csv';
import { getExportFilename } from '../../lib/get-export-filename';
import { type TReportFilter } from '../../model';

import { MenuItem, ListItemText, Menu } from './styles';

const offsetStep = 1_000;
const pendingOffsetLimit = 1_000_000;

export const DownloadButton = ({
	dateRange,
	aids,
	filters,
}: {
	aids?: number[];
	dateRange: TDateRangeValue;
	filters: TReportFilter;
}) => {
	const [offset, setOffset] = useState(0);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);
	const [isError, setError] = useState<boolean>(false);
	const [isUtmCsvPending, setIsUtmCsvPending] = useState<boolean>(false);

	const { refetch: getUtmReport } = marketingReportApiHooks.useUtmCsv(
		{
			queries: {
				aids,
				offset,
				dateFrom: mapDateToBackend(dateRange[0]),
				dateTo: mapDateToBackend(dateRange[1]),
			},
		},
		{
			retry: 0,
			onSuccess: (response) => {
				if (response.uploadUrl) {
					setOffset(0);
					setIsUtmCsvPending(false);
					window.open(response.uploadUrl, '_blank');
					return;
				}

				if (offset >= pendingOffsetLimit) {
					setError(true);
					setIsUtmCsvPending(false);
					return;
				}

				setOffset((currentOffset) => currentOffset + offsetStep);

				getUtmReport().catch(() => {
					setError(true);
				});
			},
			onError: () => {
				setIsUtmCsvPending(false);
				setError(true);
			},
			enabled: false,
		},
	);

	const {
		mutate: getGeneralReport,
		isLoading: isLoadingGeneralReport,
		isIdle: isIdleGeneralReport,
	} = marketingReportApiHooks.useGeneralCsv();

	const isReportLoading = isUtmCsvPending || (isLoadingGeneralReport && !isIdleGeneralReport);

	const isButtonDisabled =
		dateRange.includes(null) || typeof aids === 'undefined' || aids.length === 0 || isReportLoading;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCloseErrorToast = (
		_?: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason,
	) => {
		if (reason !== 'clickaway') {
			setError(false);
		}
	};

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleClick}
				sx={{ borderColor: '#E5E1E9' }}
				disabled={isButtonDisabled}
				startIcon={isReportLoading ? <SyncRoundedIcon /> : <ArrowOutwardIcon />}
			>
				Export Report as CSV
			</Button>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<MenuItem
					disabled={isButtonDisabled}
					onClick={() => {
						getGeneralReport(
							{
								aids,
								dateFrom: mapDateToBackend(dateRange[0]),
								dateTo: mapDateToBackend(dateRange[1]),
								...filters,
							},
							{
								onSuccess: (content) => {
									const blob = new Blob([`\uFEFF${content}`], { type: 'text/csv;charset=utf-8' });
									handleDownloadCsv(blob, getExportFilename(dateRange, 'general'));
								},
								onError: () => setError(true),
							},
						);
					}}
				>
					<ListItemText>General</ListItemText>
				</MenuItem>
				<MenuItem
					disabled={isButtonDisabled}
					onClick={() => {
						setIsUtmCsvPending(true);
						getUtmReport()
							.catch(() => setError(true))
							.finally(() => handleClose());
					}}
				>
					<ListItemText>Utm</ListItemText>
				</MenuItem>
			</Menu>
			<Snackbar
				open={isError}
				autoHideDuration={2000}
				onClose={handleCloseErrorToast}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleCloseErrorToast}
					severity="error"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Something went wrong. Please try again later.
				</Alert>
			</Snackbar>
		</>
	);
};
