'use client';

import * as React from 'react';
import clsx from 'clsx';
import FormControl, { type FormControlProps } from '@mui/material/FormControl';
import { type SelectChangeEvent } from '@mui/material/Select';
import FilterListIcon from '@mui/icons-material/FilterList';
import Chip from '@mui/material/Chip';

import { useDebouncedValue } from '@shared/hooks/use-debounced-value';

import { useMenuPropsWithTheme } from '../lib';
import { type TDropdownFilterType, type TDropdownSelectProps } from '../types';

import { BaseHeader } from './base-header';
import IconSvg from './svg/icon.svg';
import CheckboxSvgIcon from './svg/checkbox.svg';
import CheckboxCheckedSvgIcon from './svg/checkbox-checked.svg';
import {
	Checkbox,
	ClearAllButton,
	DropdownGhostInput,
	ListItemText,
	ListSubheader,
	MenuItem,
	StyledSelect,
} from './styles';

export const DropdownSelect = <T extends string | number | null>({
	dropdownValue,
	onDropdownSelect,
	options,
	innerTitle,
	isMultiple = false,
	placeholder = '',
	resetButton = false,
	iconType = 'default',
	forcedPlaceholder,
	headerElement,
	baseHeaderElement = false,
	...formControlProps
}: FormControlProps<'div', TDropdownSelectProps<T>>): React.ReactNode => {
	const [open, setOpen] = React.useState(false);
	const [displayOptions, setDisplayOptions] = React.useState(
		options.map((option) => (option.value === null ? { ...option, value: undefined } : option)),
	);

	const localDropdownValue = React.useMemo(
		() => dropdownValue.map((item) => (item === null ? undefined : item)),
		[dropdownValue],
	);

	const handleClose = () => {
		setOpen(false);

		setDisplayOptions(
			[...options].sort(
				(a, b) => Number(dropdownValue.includes(b.value)) - Number(dropdownValue.includes(a.value)),
			),
		);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleChange = (event: SelectChangeEvent<typeof localDropdownValue>) => {
		const { value } = event.target;
		const selectedVal = typeof value === 'string' ? (value.split(',') as T[]) : value;
		onDropdownSelect(selectedVal.map((item) => (typeof item === 'undefined' ? null : item)) as T[]);
	};

	const resetValue = (e: React.MouseEvent<HTMLElement>) => {
		onDropdownSelect([]);
		e.stopPropagation();
	};

	const menuProps = useMenuPropsWithTheme();

	const {
		value: searchString,
		setValue: setSearchString,
		debouncedValue: debouncedSearchString,
	} = useDebouncedValue<string>('', 100);

	const handleChangeOptionsHeader = React.useCallback(
		(type: TDropdownFilterType) => {
			if (type === 'all') {
				onDropdownSelect(
					displayOptions?.map(({ value }) => (typeof value === 'undefined' ? null : value) as T) ??
						[],
				);
			}
		},
		[onDropdownSelect, displayOptions],
	);

	const areAllSelected = options?.length === dropdownValue.length;

	return (
		<FormControl {...formControlProps}>
			<StyledSelect
				native={false}
				notched={undefined} // https://github.com/mui/material-ui/issues/38512
				id="demo-multiple-checkbox"
				multiple={isMultiple}
				open={open}
				onClose={handleClose}
				onOpen={handleOpen}
				value={localDropdownValue}
				onChange={handleChange}
				input={
					<DropdownGhostInput
						$isEmpty={dropdownValue.length === 0}
						endAdornment={<Chip size="small" label={dropdownValue.length} />}
					/>
				}
				renderValue={(selected) => {
					if (forcedPlaceholder) {
						return forcedPlaceholder;
					}
					if (!selected.length) {
						return placeholder;
					}

					return selected
						.map((selectedVal) => displayOptions.find(({ value }) => value === selectedVal)?.label)
						.join(', ');
				}}
				displayEmpty
				className={clsx({ 'custom-icon': iconType !== 'default' })}
				IconComponent={iconType === 'default' ? IconSvg : FilterListIcon}
				MenuProps={menuProps}
			>
				{baseHeaderElement ? (
					<BaseHeader
						searchString={searchString}
						setSearchString={setSearchString}
						filterType={areAllSelected ? 'all' : 'custom'}
						onChange={handleChangeOptionsHeader}
					/>
				) : (
					headerElement
				)}
				{innerTitle && <ListSubheader disableSticky>{innerTitle}</ListSubheader>}
				{displayOptions
					.filter(({ label }) =>
						label.toLowerCase().startsWith(debouncedSearchString.toLowerCase()),
					)
					.map(({ value, label, secondaryLabel }) => {
						const val = value === null ? undefined : value;
						return (
							<MenuItem key={`${value}`} value={val}>
								<Checkbox
									icon={<CheckboxSvgIcon />}
									checkedIcon={<CheckboxCheckedSvgIcon />}
									checked={localDropdownValue.includes(val)}
								/>

								<ListItemText primary={label} secondary={secondaryLabel} />
							</MenuItem>
						);
					})}

				{resetButton && (
					<ClearAllButton onClickCapture={resetValue}>
						<ListItemText primary="Deselect All" />
					</ClearAllButton>
				)}
			</StyledSelect>
		</FormControl>
	);
};
