import * as React from 'react';
import { useMemo, useState } from 'react';
import clsx from 'clsx';

import { type TReportTableProps } from '@shared/components/report-table/types';
import { getBaseColumnProps } from '@shared/components/report-table/utils/get-base-column-props';
import { TableLoadingState } from '@shared/components/report-table/components/table-loading-state';
import { TableErrorState } from '@shared/components/report-table/components/table-error-state';
import { TableEmptyState } from '@shared/components/report-table/components/table-empty-state';
import { SortingButton } from '@shared/components/report-table/components/sorting-button';

import {
	ReportTable,
	ReportTableBody,
	ReportTableHead,
	ReportTablePaper,
	ReportTableRowData,
	ReportTableRowNames,
	ReportTableRowTotals,
	TableCellContent,
	TableCellContentNames,
	ReportTableContainer,
	ReportTableCell,
} from './styles';

export const CommonReportTable = <ColumnId extends string>({
	columns,
	data,
	totals,
	isLoading,
	isError,
	page,
	rowsPerPage,
	sorting,
	onSortingChange,
	invalidate,
	isEmpty,
	scrollable = false,
}: TReportTableProps<ColumnId>) => {
	const [columnHovered, setColumnHovered] = useState('');
	// todo Add backend pagination later
	const pageRowData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	const isPageRowDataEmpty = !pageRowData.length;

	const columnNamesCells = columns.map((column) => (
		<ReportTableCell key={column.id} {...getBaseColumnProps(column)}>
			<TableCellContentNames $align={column.align}>
				<span>{column.label}</span>

				{column.needSorting && (
					<SortingButton
						sortingOrder={sorting[column.id]}
						onClick={() => onSortingChange(column.id)}
					/>
				)}
			</TableCellContentNames>
		</ReportTableCell>
	));

	const totalsCells = useMemo(
		() =>
			columns.map((column) => {
				if (!totals) {
					return [];
				}

				return (
					<ReportTableCell
						key={column.id}
						{...getBaseColumnProps(column, {
							className: clsx({ 'column-hovered': column.id === columnHovered }, 'total'),
						})}
						onMouseEnter={() => setColumnHovered(column.id)}
						onMouseLeave={() => setColumnHovered('')}
					>
						{totals.cells[column.id].value}
					</ReportTableCell>
				);
			}),
		[columnHovered, columns, totals],
	);

	const dataCells = useMemo(() => {
		if (isEmpty) {
			return <TableEmptyState columns={columns} />;
		}

		if (isLoading) {
			return <TableLoadingState columns={columns} />;
		}

		if (isError) {
			return <TableErrorState columns={columns} invalidate={invalidate} />;
		}

		if (isPageRowDataEmpty) {
			return <TableEmptyState columns={columns} />;
		}

		return pageRowData.map((rowData) => (
			<ReportTableRowData
				hover
				tabIndex={-1}
				key={rowData.id}
				$isWeekend={Boolean(rowData.isWeekend)}
			>
				{columns.map((column) => (
					<ReportTableCell
						key={column.id}
						{...getBaseColumnProps(column, {
							className: clsx({ 'column-hovered': column.id === columnHovered }),
						})}
						onMouseEnter={() => setColumnHovered(column.id)}
						onMouseLeave={() => setColumnHovered('')}
					>
						<TableCellContent $align={column.align}>
							{rowData.cells[column.id].render?.(rowData.cells[column.id].value) ??
								rowData.cells[column.id].value}
						</TableCellContent>
					</ReportTableCell>
				))}
			</ReportTableRowData>
		));
	}, [
		isEmpty,
		isLoading,
		isError,
		isPageRowDataEmpty,
		columns,
		pageRowData,
		columnHovered,
		invalidate,
		setColumnHovered,
	]);

	return (
		<ReportTablePaper>
			<ReportTableContainer>
				<ReportTable $scrollable={scrollable}>
					<ReportTableHead>
						<ReportTableRowNames>{columnNamesCells}</ReportTableRowNames>

						{!isError && !isPageRowDataEmpty && (
							<ReportTableRowTotals hover>{totalsCells}</ReportTableRowTotals>
						)}
					</ReportTableHead>

					<ReportTableBody>{dataCells}</ReportTableBody>
				</ReportTable>
			</ReportTableContainer>
		</ReportTablePaper>
	);
};
