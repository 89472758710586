import { makeApi } from '@zodios/core';
import { z } from 'zod';

import {
	commonError,
	generalReportParams,
	generalReportResponse,
	utmReportParams,
	utmReportResponse,
} from './schemas';

export const marketingReport = makeApi([
	{
		alias: 'general',
		method: 'post',
		path: '/cabinet/analytics/report/general-marketing/',
		response: generalReportResponse,
		parameters: generalReportParams,
		errors: commonError,
	},
	{
		alias: 'generalCsv',
		method: 'post',
		path: '/cabinet/analytics/report/general-marketing/download/',
		parameters: generalReportParams,
		response: z.string(),
		errors: commonError,
	},
	{
		alias: 'utmCsv',
		method: 'get',
		path: '/cabinet/analytics/report/utm-marketing/download/',
		parameters: utmReportParams,
		response: utmReportResponse,
		errors: commonError,
	},
]);
