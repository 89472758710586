import { parseISO, getYear, nextSunday, isValid } from 'date-fns';

import { invariant } from '@shared/utils/invariant';

const offset = {
	daylight: '-07:00',
	standard: '-08:00',
};

export const getTimezoneOffset = (dateISOStr: string) => {
	const date = parseISO(dateISOStr);

	const month = date.getMonth();

	invariant(isValid(date), 'Invalid date string passed to getTimezoneOffset');

	if ([0, 1, 11].includes(month)) {
		return offset.standard;
	}
	if ([3, 4, 5, 6, 7, 8, 9].includes(month)) {
		return offset.daylight;
	}

	const year = getYear(date);

	if (month === 2) {
		const secondSunday = nextSunday(nextSunday(new Date(year, 2)));
		return date >= secondSunday ? offset.daylight : offset.standard;
	}

	if (month === 10) {
		const firstSunday = nextSunday(new Date(year, 10));
		return date < firstSunday ? offset.daylight : offset.standard;
	}

	return offset.standard;
};
