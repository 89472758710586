import { type CSSProperties } from 'react';
import { type TableCellProps } from '@mui/material/TableCell';
import clsx from 'clsx';

import { type TColumn } from '@shared/components/report-table/types';

interface TColumnProps {
	align: TableCellProps['align'];
	style: CSSProperties;
	className?: string;
}

export const getBaseColumnProps = <T extends string>(
	column: TColumn<T>,
	externalOptions?: Partial<TColumnProps>,
): TColumnProps => {
	const isPinned = column.pinned && typeof column.pinOffset === 'number';
	return {
		align: column.align,
		className: clsx({ pinned: isPinned }, column.className, externalOptions?.className),
		style: {
			width: column.width,
			minWidth: column.minWidth,
			textAlign: column.align,
			...(isPinned ? { position: 'sticky', left: column.pinOffset } : {}),
		},
	};
};
