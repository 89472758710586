import { SearchField, type TSearchInputProps } from '@shared/components/search-input';

import { type TDropdownFilterType } from '../../types';

import { FakeButton, Button, SubTitle, ButtonStack, Box } from './style';

type BaseHeaderProps = {
	filterType: TDropdownFilterType;
	setSearchString: TSearchInputProps['setSearchString'];
	searchString: TSearchInputProps['searchString'];
	onChange: (filterType: TDropdownFilterType) => void;
};

export const BaseHeader: React.FC<BaseHeaderProps> = ({
	filterType,
	onChange,
	searchString,
	setSearchString,
}) => {
	const areAllSelected = filterType === 'all';
	const handleResetSearchString = () => setSearchString('');
	return (
		<Box>
			<SearchField
				size="sm"
				searchString={searchString}
				setSearchString={setSearchString}
				onResetSearchString={handleResetSearchString}
				placeholder="Search"
			/>
			<ButtonStack>
				<SubTitle>Show </SubTitle>
				<Button variant={areAllSelected ? 'contained' : 'outlined'} onClick={() => onChange('all')}>
					All
				</Button>
				<FakeButton disableElevation variant={!areAllSelected ? 'contained' : 'outlined'}>
					Custom
				</FakeButton>
			</ButtonStack>
		</Box>
	);
};
