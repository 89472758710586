import { formatISO, subWeeks } from 'date-fns';

import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { type TReportSorting } from '@shared/components/report-table/types';
import { generalReportResponse } from '@shared/api/marketing-report';

import {
	type TReportData,
	type TReportTotals,
	type TReportColumn,
	type TReportColumnId,
	type TReportFilterFields,
	type TReportFilter,
} from './types';

export const reportDefaultDateRange: TDateRangeValue = [
	formatISO(subWeeks(new Date(), 1)),
	formatISO(new Date()),
];

export const reportDefaultData: TReportData = [];

export const reportDefaultTotals: TReportTotals = generalReportResponse.shape.totals.parse({});

export const reportDefaultSortingOrder = Object.fromEntries(
	Object.entries(generalReportResponse.shape.data.element.shape).map(([key]) => [key, 'none']),
) as TReportSorting<TReportColumnId>;

export const reportDefaultSortingType = 'date';

export const reportTableColumns: TReportColumn[] = [
	{
		id: 'aid',
		label: 'AID',
		width: 100,
		align: 'left',
		pinned: true,
		pinOffset: 0,
	},
	{
		id: 'date',
		label: 'Date',
		width: 140,
		align: 'center',
		needSorting: true,
		pinned: true,
		pinOffset: 100,
		className: 'last-pinned',
	},
	{
		id: 'visitsCount',
		label: 'Visits',
		width: 123,
		align: 'right',
	},
	{
		id: 'visitLeadConversion',
		label: 'CR, %',
		width: 123,
		align: 'right',
	},
	{
		id: 'leadsCount',
		label: 'Leads',
		width: 123,
		align: 'right',
	},
	{
		id: 'leadClickConversion',
		label: 'CR, %',
		width: 123,
		align: 'right',
	},
	{
		id: 'clicksCount',
		label: 'Listing Clicks',
		width: 123,
		align: 'right',
	},
	{
		id: 'revenue',
		label: 'Revenue',
		width: 123,
		align: 'right',
	},
	{
		id: 'epl',
		label: 'EPL, $',
		width: 123,
		align: 'right',
	},
	{
		id: 'epc',
		label: 'EPC, $',
		width: 123,
		align: 'right',
	},
];

export const reportFilterNames = Object.keys(
	generalReportResponse.shape.filter.shape,
) as TReportFilterFields[];

export const initialFilterState = reportFilterNames.reduce<TReportFilter>(
	(acc, filterName) => ({
		...acc,
		[filterName]: [],
	}),
	{} as TReportFilter,
);
