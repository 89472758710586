'use client';

import { styled } from '@mui/material';

export const ReportContainer = styled('div')(({ theme }) => ({
	marginTop: theme.typography.pxToRem(16),
	borderRadius: theme.typography.pxToRem(12),
	border: `1px solid ${theme.palette.custom.component.tableSectionDivider}`,
}));

export const ReportPaper = styled('div')(({ theme }) => ({
	borderRadius: theme.typography.pxToRem(12),
	backgroundColor: theme.palette.background.paper,

	'& > *:first-child': {
		borderTopLeftRadius: 'inherit',
		borderTopRightRadius: 'inherit',
	},

	'& > *:last-child': {
		borderBottomLeftRadius: 'inherit',
		borderBottomRightRadius: 'inherit',
	},

	'& > *:not(:last-child)': {
		borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.custom.component.tableSectionDivider}`,
	},
}));
