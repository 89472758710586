import { type TRowData } from '@shared/components/report-table/types';
import { getAmountFromCents } from '@shared/utils/number';

import { type TReportTotals, type TReportColumnId } from '../model';

export const mapTotalsFromBackend = (totals: TReportTotals): TRowData<TReportColumnId> => ({
	id: 'totals',
	cells: {
		aid: {
			value: 'Totals',
		},
		date: { value: '' },
		visitsCount: {
			value: String(totals.visitsCount),
		},
		visitLeadConversion: {
			value: String(totals.visitLeadConversion),
		},
		leadsCount: {
			value: String(totals.leadsCount),
		},
		leadClickConversion: {
			value: String(totals.leadClickConversion),
		},
		clicksCount: {
			value: String(totals.clicksCount),
		},
		revenue: {
			value: getAmountFromCents(totals.revenue).toFixed(2),
		},
		epl: {
			value: getAmountFromCents(totals.epl).toFixed(2),
		},
		epc: {
			value: getAmountFromCents(totals.epc).toFixed(2),
		},
	},
	isWeekend: false,
	isPartialData: false,
});
