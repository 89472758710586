import {
	MessageErrorOverlay,
	MessageErrorOverlayDescription,
	ReloadPageButton,
	WarningIconContainer,
} from '@shared/components/report-table/components/table-error-state/styles';
import { MessageOverlayTitle } from '@shared/components/report-table/styles';

import WarningIcon from '../images/warning-icon.svg';

type TTableErrorMessageProps = {
	invalidate(): Promise<void>;
};

export const TableErrorMessage: React.FC<TTableErrorMessageProps> = ({ invalidate }) => {
	const handleReload = () => {
		invalidate().catch((error) => {
			console.error(error);
		});
	};

	return (
		<MessageErrorOverlay>
			<WarningIconContainer>
				<WarningIcon />
			</WarningIconContainer>

			<MessageOverlayTitle>Unable to load data</MessageOverlayTitle>

			<MessageErrorOverlayDescription>
				There’s a technical problem with LinksLane, that prevent this data from loading. Please try
				reloading this page
			</MessageErrorOverlayDescription>

			<ReloadPageButton variant="contained" onClick={handleReload}>
				Reload this page
			</ReloadPageButton>
		</MessageErrorOverlay>
	);
};
