'use client';

import { usePathname } from 'next/navigation';

import { DASHBOARD_PAGE_PATH, DETAILED_REPORT_PATH, OVERVIEW_REPORT_PATH } from '@shared/constants';
import { useRole } from '@entities/roles';

import { SidebarMenuItem } from './sidebar-menu-item';
import { MenuList, ListSubheader } from './styles';
import PieChartAltIcon from './svg/pie-chart-alt.svg';
import BarChartIcon from './svg/bar-chart.svg';
import TableIcon from './svg/table.svg';

export const SidebarMenu: React.FC = () => {
	const pathname = usePathname();
	const { isAffiliate } = useRole();
	return (
		<MenuList>
			<SidebarMenuItem
				label="Dashboard"
				path={DASHBOARD_PAGE_PATH}
				icon={<PieChartAltIcon />}
				selected={pathname === DASHBOARD_PAGE_PATH}
			/>

			{isAffiliate && <ListSubheader>Reports</ListSubheader>}

			{isAffiliate && (
				<SidebarMenuItem
					label="Overview"
					path={OVERVIEW_REPORT_PATH}
					icon={<BarChartIcon />}
					selected={pathname === OVERVIEW_REPORT_PATH}
				/>
			)}

			{isAffiliate && (
				<SidebarMenuItem
					label="Detailed"
					path={DETAILED_REPORT_PATH}
					icon={<TableIcon />}
					selected={pathname === DETAILED_REPORT_PATH}
				/>
			)}
		</MenuList>
	);
};
