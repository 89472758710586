import MuiMenuItem from '@mui/material/MenuItem';
import MuiListItemText from '@mui/material/ListItemText';
import MuiMenu from '@mui/material/Menu';
import { styled } from '@mui/material';

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
	borderRadius: theme.spacing(0.75),
	gap: theme.spacing(1),
	padding: theme.spacing(1),

	'&.Mui-selected:hover': {
		backgroundColor: theme.palette.custom.component.overlay,
	},
	'&.Mui-selected': {
		background: 'none',
	},
}));

export const ListItemText = styled(MuiListItemText)(({ theme }) => ({
	...theme.typography.body1,
	color: theme.palette.custom.dark.highest,
}));

export const Menu = styled(MuiMenu)(({ theme }) => ({
	'.MuiMenu-paper': {
		minWidth: theme.spacing(20),
		borderRadius: theme.spacing(1.5),
	},
	'.MuiMenu-list': {
		padding: theme.spacing(1.5, 1),
	},
}));
